import { Routes, Route } from "react-router-dom";

import "./App.css";
import React from "react";
import Loader from "./layouts/Loader/Loader";

/* Imports lazy para tener mejor rendimiento en la app, muestra un loader al cargar el componente */

const Menu = React.lazy(() => import("./layouts/Menu/Menu"));
const HomePage = React.lazy(() => import("./pages/HomePage/HomePage"));
const CatalogoNFT = React.lazy(() => import("./pages/CatalogoNFT/CatalogoNFT"));

const MainLayout = React.lazy(() => import("./layouts/MainLayout/MainLayout"));

function App() {
  return (
    <div className="App">
      <React.Suspense fallback={<Loader />}>
        <Menu />
        <Routes>
          <Route path="/" element={<MainLayout />}>
            <Route index element={<HomePage />}></Route>
            <Route path="loader" element={<Loader />} />
            <Route path="catalogo-nft" element={<CatalogoNFT />} />
          </Route>
        </Routes>
        {/* <Routes>
          <Route path="auth" element={<AuthLayout />}>
            <Route path="login" element={<SignInPage />} />
            <Route path="register" element={<RegisterPage />} />
          </Route>
        </Routes> */}
      </React.Suspense>
    </div>
  );
}

export default App;
