import CircularProgress from "@mui/material/CircularProgress/CircularProgress";

import logo from "../../assets/logo-color.png";
import "./styles.css";

const Loader = () => {
  return (
    <div className="loader">
      <div className="">
        <img src={logo} alt="" />
        <CircularProgress color="primary" />
      </div>
    </div>
  );
};

export default Loader;
